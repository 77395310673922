<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-dialog v-model="dialog" persistent max-width="1000px">
          <template v-slot:activator="{ on }"> </template>
          <v-card>
            <v-form
              @submit.prevent="savePost"
              ref="formTeltonika"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-card-title>
                <span class="headline">API Details</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        disabled
                        label="Name"
                        v-model="formTeltonika.name"
                        color="#2C3A47"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        disabled
                        label="API Key"
                        v-model="formTeltonika.api"
                        color="#2C3A47"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        disabled
                        label="Ip Adress"
                        v-model="formTeltonika.ipAdress"
                        color="#2C3A47"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        disabled
                        label="Protocol"
                        v-model="formTeltonika.protocol"
                        color="#2C3A47"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        label="Connection ID"
                        v-model="formTeltonika.connect"
                        color="#2C3A47"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#2C3A47" dark @click="close" outlined small>
                  <v-icon left>mdi-close</v-icon>Close
                </v-btn>
                <v-btn
                  color="light-green darken-4"
                  dark
                  type="submit"
                  outlined
                  small
                >
                  <v-icon left>mdi-check-all</v-icon> {{ submit }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col md="5" class="mx-auto mt-6">
            <v-card class="card-shadow border-radius-xl">
              <v-card-title>
                <v-icon x-large left> mdi-broadcast </v-icon>
                <span class="text-h5 font-weight-bold"
                  >My IoT Hub Device API Settings</span
                >
              </v-card-title>
              <v-card-text class="text-h6 font-weight-regular">
                You have to create API Auth Key on your RMS. For more
                documentation please click.
              </v-card-text>

              <div class="px-5 pt-2">
                <v-form
                  @submit.prevent="savePost"
                  ref="formTeltonika"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-text-field
                    v-model="formTeltonika.name"
                    :rules="nameRules"
                    label="API Name"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="formTeltonika.api"
                    :rules="nameRules"
                    label="API Key"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="formTeltonika.id"
                    :rules="emailRules"
                    label="Device Serial ID"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="formTeltonika.ipAdress"
                    :rules="ipRules"
                    label="Your Device IP Adress (Default is 192.168.0.1)"
                    required
                  ></v-text-field>

                  <v-select
                    v-model="formTeltonika.protocol"
                    :items="items"
                    :rules="[(v) => !!v || 'Protocol is required']"
                    label="Protocol"
                    required
                  ></v-select>

                  <v-btn color="green" dark class="mr-4" type="submit">
                    {{ submit }}
                  </v-btn>
                </v-form>
              </div>
              <div class="px-5 pt-5"></div>
            </v-card>
          </v-col>
          <v-col md="5" class="mx-auto mt-6">
            <v-card class="card-shadow border-radius-xl">
              <v-card-title>
                <v-icon x-large left> mdi-broadcast </v-icon>
                <span class="text-h5 font-weight-bold">Mqtt Settings</span>
              </v-card-title>
              <v-card-text class="text-h6 font-weight-regular">
                Your MQTT Broker & Subscribe & Publish Settings. For more
                documentation please click.
              </v-card-text>

              <div class="px-5 pt-2">
                <v-form
                  @submit.prevent="savePost"
                  ref="formMqtt"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-text-field
                    v-model="formMqtt.host"
                    :rules="nameRules"
                    label="Mqtt Host"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="formMqtt.port"
                    :rules="nameRules"
                    label="Port"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="formMqtt.username"
                    :rules="emailRules"
                    label="Username"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="formMqtt.password"
                    :rules="ipRules"
                    label="Password"
                    required
                  ></v-text-field>

                  <v-select
                    v-model="formMqtt.topic"
                    :items="items"
                    :rules="[(v) => !!v || 'Protocol is required']"
                    label="Topic"
                    required
                  ></v-select>

                  <v-btn color="green" dark class="mr-4" type="submit">
                    {{ submit }}
                  </v-btn>
                </v-form>
              </div>
              <div class="px-5 pt-5"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="pa-2">
            <v-container>
              <v-card class="mx-auto elevation-0">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify-plus"
                    label=""
                    single-line
                    hide-details
                    color="#2C3A47"
                  >
                  </v-text-field>
                </v-card-title>
                <v-data-table
                  item-key="id"
                  :headers="headers"
                  :items="posts"
                  :search="search"
                  loading
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="green"
                      fab
                      x-small
                      @click="editPost(item)"
                      outlined
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      color="pink"
                      fab
                      x-small
                      @click="deletePost(item._id)"
                      outlined
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="orange"
                      fab
                      x-small
                      @click="postRmsConnect(item._id)"
                      outlined
                    >
                      <v-icon small>mdi-restart</v-icon>
                    </v-btn>
                    <v-btn
                      color="blue"
                      fab
                      x-small
                      @click="getRmsLink(item._id)"
                      outlined
                    >
                      <v-icon small>mdi-connection</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:no-results>
                    <v-alert :value="true" color="pink" icon="mdi-alert" dark>
                      Your Search {{ search }} does not exist.
                    </v-alert>
                  </template>
                </v-data-table>
              </v-card>
            </v-container>
          </v-card>
          <v-snackbar v-model="snackbar" top right :color="color">
            {{ text }}
            <v-btn color="black" text @click="snackbar = false"> Okay </v-btn>
          </v-snackbar>
        </v-col>
      </v-row>
      <v-row
        ><v-col md="12" class="mx-auto mt-6">
          <v-card>
            <div class="px-10 pt-5 text-center">
              {{ link }}
              <div class="px-10 pt-5"></div>
            </div>
          </v-card> </v-col
      ></v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    link: null,
    mini: false,
    dialog: false,
    fab: false,
    search: "",
    snackbar: false,
    text: "",
    color: "",
    posts: [],
    headers: [
      { text: "Api Name", value: "name" },
      { text: "Device ID", value: "id" },
      { text: "Ip Adress", value: "ipAdress" },
      { text: "Protocol", value: "protocol" },
      { text: "Action", value: "actions", width: "180px" },
    ],
    nameRules: [(v) => !!v || "API Key is required"],
    emailRules: [
      (v) => !!v || "Device Serial ID is required",
      //v => (v && v.length == 10) || 'Name must be less than 10 characters',
    ],
    ipRules: [(v) => !!v || "IP adress is required"],
    items: ["Https", "Http", "SFTP", "RDP", "SSH", "Telnet"],
    formMqtt: {
      host: "",
      port: "",
      username: "",
      password: "",
      topic: "",
    },
    formTeltonika: {
      name: "",
      api: "",
      id: "",
      ipAdress: "",
      protocol: "",
      connect: "",
    },

    default: {
      name: "",
      api: "",
      id: "",
      ipAdress: "",
      protocol: "",
      connect: "",
    },
    editedIndex: -1,
  }),

  mounted() {
    this.loadPosts();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    submit() {
      return this.editedIndex === -1 ? "Save" : "Edit";
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    loadPosts: async function () {
      let apiURL = "http://localhost:3000/teltonika";
      axios
        .get(apiURL)
        .then((res) => {
          this.posts = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.formTeltonika = Object.assign({}, this.default);
        this.editedIndex = -1;
      }, 300);
    },

    savePost: async function () {
      if (this.editedIndex > -1) {
        this.updatePost();
      } else {
        this.createPost();
      }
    },
    createPost() {
      let apiURL = "http://localhost:3000/teltonika/create-post";
      axios
        .post(apiURL, this.formTeltonika)
        .then(() => {
          this.formTeltonika = {
            name: "",
            api: "",
            id: "",
            ipAdress: "",
            protocol: "",
            connect: "",
          };
          this.loadPosts();
          this.color = "success";
          this.text = "Post has been successfully saved.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editPost(item) {
      this.editedIndex = this.posts.indexOf(item);
      this.formTeltonika = Object.assign({}, item);
      this.dialog = true;
    },

    updatePost() {
      let apiURL = `http://localhost:3000/teltonika/update-post/${this.formTeltonika._id}`;
      axios
        .post(apiURL, this.formTeltonika)
        .then((res) => {
          console.log(res);
          this.close();
          this.loadPosts();
          this.color = "info";
          this.text = "Post has been modified.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          console.log(this.$route.params.id);
        });
    },

    deletePost(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to go back",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00b894",
        cancelButtonColor: "#d63031",
        confirmButtonText: "Yes, Delete it!",
        cancelButtonText: "No, Dont Delete!",
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          try {
            let apiURL = `http://localhost:3000/teltonika/delete-post/${id}`;
            let indexOfArrayItem = this.posts.findIndex((i) => i._id === id);
            axios
              .delete(apiURL)
              .then(() => {
                this.posts.splice(indexOfArrayItem, 1);
              })
              .catch((error) => {
                console.log(error);
              });
            this.$swal("Deleted!", "Post has been deleted.", "success");
            //Notification error
            this.color = "error";
            this.text = "Post has been deleted.";
            this.snackbar = true;
            //Notification error
          } catch (error) {
            this.$swal("Failed!", "There was something wrong.", "warning");
          }
        }
      });
    },

    async postRmsConnect() {
      this.loadPosts();
      this.color = "green";
      this.text = "Server is starting in 10 seconds.";
      this.snackbar = true;

      var axios = require("axios");
      var data = JSON.stringify({
        duration: 400,
      });

      var config = {
        method: "post",
        url: "https://rms.teltonika-networks.com/api/devices/connect/654988",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJqdGkiOiI2ZWM5NDNiOTQ1YzcyODJjMmFhODE4NzNkNTEwYWJiMzNhNmNhODcxZjU4MmM4NGMzM2FlNzA5ZDY1MzY3NzRmYjVmYzA5YWY2Y2NhZDc5NyIsImlzcyI6Imh0dHBzOlwvXC9ybXMudGVsdG9uaWthLW5ldHdvcmtzLmNvbVwvYWNjb3VudCIsImlhdCI6MTY1NzQ1NTg4MywibmJmIjoxNjU3NDU1ODgzLCJzdWIiOiI2MTIzNyIsImNsaWVudF9pZCI6IjkxMjNlYTY2LTJmMWQtNDM5Yy1iMWMyLTMxMWFjMDEwYWFhZCIsImZpcnN0X3BhcnR5IjpmYWxzZX0.eV7Ks8gKZCy_loiS93ibh2kRFx1v4eiIElHwW9HieOvJaDqNJdOxDkSJ0bEmOU6xwd3yXzYQSNfVc_AFoMZNkRW5sXAGE3OEFNBoHr1m_rteyylSyhXngF45SVQXwkqjJDXpVKaQw68qwnQ6PGsIVp7Ftv7nGRE0ZE1LG23VhcWmlAaLPgHlqCpvKVqgCwQ90nNZiVY3D55NJnfAx58e32fS68A-wPxd0mR7xL8ZC2JZlcVkoy8MQwURPIgnCUlxafl3Z9NeKrVztLJEVpQodSvQanJ9AZbb5kMujkP7dXYUSrfiMlBVSizHxfkclG9f9gYz1k_ihP_sN1H_yyDF4A",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async getRmsLink(id) {
      let apiURL2 = "http://localhost:3000/teltonika/";
      let indexOfArrayItem = this.posts.findIndex((i) => i._id === id);
      this.$axios.get(apiURL2).then((response) => {
        var name = response.data[indexOfArrayItem].name;
        var api = response.data[indexOfArrayItem].api;
        var id = response.data[indexOfArrayItem].id;
        var ipAdress = response.data[indexOfArrayItem].ipAdress;
        var protocol = response.data[indexOfArrayItem].protocol;
        var connect = response.data[indexOfArrayItem].connect;

        console.log(name);
        console.log(api);
        console.log(id);
        console.log(ipAdress);
        console.log(protocol);
        console.log(connect);

        var config5 = {
          method: "get",
          url: `https://rms.teltonika-networks.com/api/devices/connect/${connect}/sessions?active=true`,
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJqdGkiOiI2ZWM5NDNiOTQ1YzcyODJjMmFhODE4NzNkNTEwYWJiMzNhNmNhODcxZjU4MmM4NGMzM2FlNzA5ZDY1MzY3NzRmYjVmYzA5YWY2Y2NhZDc5NyIsImlzcyI6Imh0dHBzOlwvXC9ybXMudGVsdG9uaWthLW5ldHdvcmtzLmNvbVwvYWNjb3VudCIsImlhdCI6MTY1NzQ1NTg4MywibmJmIjoxNjU3NDU1ODgzLCJzdWIiOiI2MTIzNyIsImNsaWVudF9pZCI6IjkxMjNlYTY2LTJmMWQtNDM5Yy1iMWMyLTMxMWFjMDEwYWFhZCIsImZpcnN0X3BhcnR5IjpmYWxzZX0.eV7Ks8gKZCy_loiS93ibh2kRFx1v4eiIElHwW9HieOvJaDqNJdOxDkSJ0bEmOU6xwd3yXzYQSNfVc_AFoMZNkRW5sXAGE3OEFNBoHr1m_rteyylSyhXngF45SVQXwkqjJDXpVKaQw68qwnQ6PGsIVp7Ftv7nGRE0ZE1LG23VhcWmlAaLPgHlqCpvKVqgCwQ90nNZiVY3D55NJnfAx58e32fS68A-wPxd0mR7xL8ZC2JZlcVkoy8MQwURPIgnCUlxafl3Z9NeKrVztLJEVpQodSvQanJ9AZbb5kMujkP7dXYUSrfiMlBVSizHxfkclG9f9gYz1k_ihP_sN1H_yyDF4A",
          },
        };
        axios(config5)
          .then((response) => {
            this.link = response.data.data[0].url;
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
  },
};
</script>
