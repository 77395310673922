<template>
  <v-container fluid class="py-6">

    <!-- form page and area -->
    <v-dialog v-model="showAddAreaModal" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            {{areaModalTitle}}
          </span>
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="areaForm.name" hide-details autofocus class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                " dense flat filled solo height="43" @change="onChangeAreaForm('name')" placeholder="Area Name" />
                <span class="text-danger" v-for="item in errors" v-if="item === 'name'" v-bind:key="item">
                  Field is required.
                </span>
              </v-col>
              <v-col cols="12" v-if="isAddAreaPage">
                <v-select :items="factories" v-model="areaForm.factoryId" item-text="name" item-value="_id"
                  color="rgba(0,0,0,.6)" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-0
                  mb-0
                  select-path
                " outlined single-line height="43" @change="onChangeAreaForm('factoryId')" placeholder="Select path">
                </v-select>
                <span class="text-danger" v-for="item in errors" v-if="item === 'factoryId'" v-bind:key="item">
                  Please select path.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn @click="toggleAddAreaModal(false)" elevation="0" :ripple="false" height="43" class="
            font-weight-bold
            text-capitalize
            btn-ls btn-secondary
            bg-gradient-light
            py-3
            px-6
          ">Cancel</v-btn>

          <v-btn @click="submitAreaPage" :loading="isSaving" elevation="0" :ripple="false" height="43" class="
            font-weight-bold
            text-capitalize
            btn-ls btn-primary
            bg-gradient-primary
            py-3
            px-6
          ">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- form pool -->
    <v-dialog v-model="showAddPoolModal" persistent max-width="768px">
      <v-card>
        <v-form @submit.prevent="submitPool" ref="poolForm" lazy-validation enctype="multipart/form-data"
          autocomplete="off" class="pb-3">
          <div class="card-header-padding card-border-bottom">
            <span class="font-weight-bold text-h5 text-typo mb-0">
              {{addPoolModalTitle}}
            </span>
          </div>
          <v-card-text>
            <v-container grid-list-md>
              <v-col cols="12" v-if="!poolForm.isSelectedType">
                <v-select :items="['Nursery', 'Cultivation']" v-model="poolForm.type" color="rgba(0,0,0,.6)" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-0
                  mb-0
                  select-path
                " outlined single-line height="43" placeholder="Select pool type">
                </v-select>
                <span class="text-danger" v-for="item in errors" v-if="item === 'type'" v-bind:key="item">
                  Please select type.
                </span>
              </v-col>
              <v-row v-else>
                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Farmer*</label>
                  <v-text-field v-model="poolForm.farmer" hide-details autofocus
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Farmer" @change="onChangeAreaForm('farmer')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'farmer'" v-bind:key="item">
                    Farmer is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Crop type*</label>
                  <v-text-field v-model="poolForm.cropType" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Crop type" @change="onChangeAreaForm('cropType')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'cropType'" v-bind:key="item">
                    Crop type is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Crop Variety*</label>
                  <v-text-field v-model="poolForm.cropVariety" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Crop Variety" @change="onChangeAreaForm('cropVariety')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'cropVariety'" v-bind:key="item">
                    Crop Variety is required.
                  </span>
                </v-col>
                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Tray Size*</label>
                  <v-text-field v-model="poolForm.traySize" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Tray Size" @change="onChangeAreaForm('traySize')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'traySize'" v-bind:key="item">
                    Tray Size is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Harvest Type*</label>
                  <v-text-field v-model="poolForm.harvestType" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Harvest Type" @change="onChangeAreaForm('harvestType')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'harvestType'" v-bind:key="item">
                    Harvest Type is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Harvest Target*</label>
                  <v-text-field v-model="poolForm.harvestTarget" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Harvest Target" @change="onChangeAreaForm('harvestTarget')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'harvestTarget'" v-bind:key="item">
                    Harvest Target is required.
                  </span>
                </v-col>
                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Harvest Date*</label>
                  <v-text-field v-model="poolForm.harvestDate" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Harvest Date" type="date" @change="onChangeAreaForm('harvestDate')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'harvestDate'" v-bind:key="item">
                    Harvest Date is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Expected Harvest Date*</label>
                  <v-text-field v-model="poolForm.eHarvestDate" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Expected Harvest Date" type="date"
                    @change="onChangeAreaForm('eHarvestDate')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'eHarvestDate'" v-bind:key="item">
                    Expected Harvest Date is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Transplant Date*</label>
                  <v-text-field v-model="poolForm.transplantDate" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Transplant Date" type="date"
                    @change="onChangeAreaForm('transplantDate')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'transplantDate'" v-bind:key="item">
                    Transplant Date is required.
                  </span>
                </v-col>

                <v-col sm="6" cols="12" class="pt-0">
                  <label class="text-xs text-typo font-weight-bolder mb-2">Expected Transplant Date*</label>
                  <v-text-field v-model="poolForm.eTransplantDate" type="date" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Expected Transplant Date"
                    @change="onChangeAreaForm('eTransplantDate')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'eTransplantDate'" v-bind:key="item">
                    Expected Transplant Date is required.
                  </span>
                </v-col>

                <v-col sm="12" cols="12">
                  <label class="text-xs text-typo font-weight-bolder">Price*</label>
                  <v-text-field v-model="poolForm.price" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Price" type="number" min="0" @change="onChangeAreaForm('price')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'price'" v-bind:key="item">
                    Price is required.
                  </span>
                </v-col>

                <v-col sm="12" cols="12">
                  <label class="text-xs text-typo font-weight-bolder">Notes*</label>
                  <v-text-field v-model="poolForm.notes" hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat filled
                    solo height="43" placeholder="Notes" @change="onChangeAreaForm('notes')" />
                  <span class="text-danger" v-for="item in errors" v-if="item === 'notes'" v-bind:key="item">
                    Notes is required.
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="mr-4">
            <v-spacer></v-spacer>
            <v-btn @click="toggleAddPoolModal" v-if="!poolForm.isSelectedType || selectedPool != null" elevation="0"
              :ripple="false" height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6">
              Close
            </v-btn>

            <v-btn @click="poolForm.isSelectedType = false; errors=[]" elevation="0" :ripple="false" height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6" v-else>
              Back
            </v-btn>

            <v-btn class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
              :loading="isSaving" elevation="0" :ripple="false" height="43" type="submit" outlined small
              v-if="poolForm.isSelectedType">
              <v-icon left>mdi-check-all</v-icon>Save
            </v-btn>

            <v-btn class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
              :loading="isSaving" elevation="0" :ripple="false" height="43" @click="onNextClick" type="button" outlined
              small v-else>
              <v-icon left>mdi-check-all</v-icon> {{poolForm.type === 'Cultivation' ? 'Save' : 'Next'}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-container fluid class="px-4 py-4">
      <v-row class="px-6 py-6">
        <v-btn :ripple="false" @click="toggleAddAreaModal(true)" height="43"
          class="font-weight-bold text-capitalize py-3">
          <v-icon size="16">mdi-plus-thick</v-icon> Add Area Page
        </v-btn>
        <v-btn :ripple="false" @click="editAreaPage" height="43" class="font-weight-bold text-capitalize py-3 mx-2"
          v-if="!!selectedTraceabilityId">
          <v-icon size="16">mdi-pencil</v-icon> Edit current area Page
        </v-btn>
        <v-btn :ripple="false" @click="deleteAreaPage" height="43" class="font-weight-bold text-capitalize py-3"
          v-if="!!selectedTraceabilityId">
          <v-icon size="16">mdi-delete</v-icon> Delete current area Page
        </v-btn>
      </v-row>

      <v-card class="px-6 py-3" v-if="traceabilityTabs.length>0">
        <v-col lg="12" cols="12">
          <v-tabs light next-icon="mdi-arrow-right-bold-box-outline" v-model="selectedTraceabilityId"
            prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
            <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab v-for="item in traceabilityTabs" :key="item._id" :href="`#${item._id}`">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="12">
          <v-tabs-items v-model="selectedTraceabilityId" class="transparent ">
            <v-tab-item v-for="area in traceabilityTabs" :key="area._id" :value="area._id">
              <v-row>
                <v-col lg="6" cols="12" v-for="area in areasOfTraceability" :key="area._id">
                  <v-card outlined class=" border-radius-xl h-100">
                    <div class="px-4 py-6">
                      <div class="d-flex justify-space-between align-center mb-6">
                        <h6 class="text-h6 text-typo font-weight-bold">
                          {{area.name}}
                        </h6>
                        <div class="d-flex justify-space-between">
                          <v-btn color="accent" class="text-capitalize" outlined @click="onAddPool(area._id)">
                            <v-icon size="16">mdi-plus</v-icon> Add pool
                          </v-btn>
                          <v-btn color="accent" class="text-capitalize mx-2" outlined @click="onEditArea(area)">
                            <v-icon size="16">mdi-pencil</v-icon> Edit area
                          </v-btn>
                          <v-btn color="accent" class="text-capitalize" outlined @click="onDeleteArea(area)">
                            <v-icon size="16">mdi-delete</v-icon> Delete area
                          </v-btn>
                        </div>
                      </div>

                      <v-row>
                        <v-col lg="6" cols="4" v-for="pool in area.pools" :key="pool._id">
                          <div class="px-2 pb-2">
                            <v-alert :color="pool.type === 'cultivation'? 'accent' : 'green'" prominent outlined
                              :icon="pool.type === 'cultivation'? 'mdi-plus' : 'mdi-sprout-outline'" class="mb-0">
                              <div class="d-flex flex-end justify-end">
                                <v-icon @click="editPool(pool)" size="24" class="mr-4" v-if="pool.type === 'nursery'">
                                  mdi-pencil</v-icon>
                                <v-icon @click="deletePool(pool)" size="24">mdi-delete</v-icon>
                              </div>
                            </v-alert>
                          </div>
                        </v-col>

                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-btn @click="toggleAddAreaModal(false)" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-primary
                bg-gradient-secondary
                py-3
                mt-6
              ">
                <v-icon size="16">mdi-plus-thick</v-icon> Add Area
              </v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "Report",
  props: {
    source: String,
  },
  data: () => ({
    showAddAreaModal: false,
    showAddPoolModal: false,
    isSaving: false,
    factories: [],
    areaForm: { name: '', factoryId: '' },
    poolForm: {
      areaId: '',
      type: '',
      farmer: '',
      cropType: '',
      cropVariety: '',
      traySize: '',
      harvestTarget: '',
      eTransplantDate: '',
      eHarvestDate: '',
      transplantDate: '',
      harvestDate: '',
      harvestType: '',
      price: '',
      notes: '',
    },
    errors: [],
    traceabilityTabs: [],
    selectedTraceabilityId: null,
    areasOfTraceability: [],
    isAddAreaPage: false,
    isEditAreaPage: false,
    selectedArea: null,
    selectedPool: null,
  }),
  computed: {
    areaModalTitle() {
      if (this.selectedArea) {
        return 'Edit Area';
      }
      if (this.isEditAreaPage) {
        return 'Edit Area Page';
      }
      return this.isAddAreaPage ? 'Add Area Page' : 'Add New Area';
    },
    addPoolModalTitle() {
      if (this.selectedPool) {
        return 'Edit Nursery Pool';
      }
      if (this.poolForm.type) {
        return this.poolForm.type === 'Nursery' ? 'New Nursery Pool' : 'New Cultivation Pool';
      }
      return 'Select Pool Type';
    }
  },
  mounted() {
    this.fetchFactories();
    this.fetchTraceabilityTabs();
    // this.loadPosts();
  },
  watch: {
    selectedTraceabilityId() {
      this.fetchAreasOfTraceability();
    },
  },
  methods: {
    onAddPool(areaId) {
      this.toggleAddPoolModal();
      this.poolForm.areaId = areaId;
    },
    async deletePool(pool) {
      try {
        await this.$axios.delete('/api/pool/' + pool._id);
        this.fetchAreasOfTraceability();
      } catch (error) {
        console.log(error);
      }
    },
    editPool(pool) {
      this.toggleAddPoolModal();
      this.selectedPool = pool;
      this.poolForm = {
        areaId: pool.areaId,
        type: pool.type,
        farmer: pool.farmer,
        cropType: pool.cropType,
        cropVariety: pool.cropVariety,
        traySize: pool.traySize,
        harvestTarget: pool.harvestTarget,
        eTransplantDate: dayjs(pool.eTransplantDate).format('YYYY-MM-DD'),
        eHarvestDate: dayjs(pool.eHarvestDate).format('YYYY-MM-DD'),
        transplantDate: dayjs(pool.transplantDate).format('YYYY-MM-DD'),
        harvestDate: dayjs(pool.harvestDate).format('YYYY-MM-DD'),
        harvestType: pool.harvestType,
        price: pool.price,
        notes: pool.notes,
        isSelectedType: true,
      };
    },
    onEditArea(area) {
      this.toggleAddAreaModal();
      this.selectedArea = area;
      this.areaForm = { name: area.name };
    },
    editAreaPage() {
      const tab = this.traceabilityTabs.find(t => t._id === this.selectedTraceabilityId);
      if (tab) {
        this.toggleAddAreaModal();
        this.isAddAreaPage = true;
        this.isEditAreaPage = true;
        this.areaForm = {
          name: tab.name,
          factoryId: tab.factoryId,
        };
      }
    },
    async deleteAreaPage() {
      try {
        await this.$axios.delete('/api/traceability/' + this.selectedTraceabilityId);
        this.fetchTraceabilityTabs();
      } catch (error) {
        console.log(error);
      }
    },
    async onDeleteArea(area) {
      try {
        await this.$axios.delete('/api/area/' + area._id);
        this.fetchAreasOfTraceability();
      } catch (error) {
        console.log(error);
      }
    },
    async onNextClick(e) {
      e.preventDefault();
      if (this.poolForm.type) {
        if (this.poolForm.type === 'Cultivation') {
          this.isSaving = true;
          try {
            await this.$axios.post('/api/pool', {
              type: 'cultivation',
              areaId: this.poolForm.areaId
            });
            this.fetchAreasOfTraceability();
            this.toggleAddPoolModal();
          } catch (error) {
            console.log(error);
          }
          this.isSaving = false;
        }
        this.poolForm.isSelectedType = true;
        this.errors = [];
      } else {
        this.errors.push('type');
      }
    },
    async submitPool() {
      for (const field in this.poolForm) {
        if (!this.poolForm[field] || this.poolForm[field].trim?.().length === 0) {
          this.errors.push(field);
        }
      }
      if (this.errors.length === 0) {
        try {
          this.isSaving = true;
          if (this.selectedPool) {
            await this.$axios.put('/api/pool/' + this.selectedPool._id, this.poolForm);
          } else {
            await this.$axios.post('/api/pool', {
              ...this.poolForm,
              type: this.poolForm.type.toLowerCase(),
            });
          }
          this.fetchAreasOfTraceability();
          this.toggleAddPoolModal();
        } catch (error) {
          console.log(error);
        }
        this.isSaving = false;
      }
    },
    async fetchFactories() {
      try {
        const { data } = await this.$axios.get('/api/factory');
        this.factories = data;
      } catch (error) { }
    },
    async fetchTraceabilityTabs() {
      try {
        const { data } = await this.$axios.get('/api/traceability');
        this.traceabilityTabs = data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAreasOfTraceability() {
      try {
        const { data } = await this.$axios.get('/api/area?traceabilityId=' + this.selectedTraceabilityId);
        this.areasOfTraceability = data;
      } catch (error) {
        console.log(error);
      }
    },
    async submitAreaPage() {
      try {
        const fields = this.isAddAreaPage ? ['name', 'factoryId'] : ['name'];
        this.errors = fields.filter(field => this.areaForm[field].trim().length === 0);

        if (this.errors.length === 0) {
          this.isSaving = true;
          if (this.isAddAreaPage) {
            if (this.isEditAreaPage) {
              await this.$axios.put('/api/traceability/' + this.selectedTraceabilityId, {
                name: this.areaForm.name,
                factoryId: this.areaForm.factoryId
              });
            } else {
              await this.$axios.post('/api/traceability', {
                name: this.areaForm.name,
                factoryId: this.areaForm.factoryId
              });
            }
            this.fetchTraceabilityTabs();
          } else {
            // add area in page
            if (this.selectedArea) {
              await this.$axios.put('/api/area/' + this.selectedArea._id, { name: this.areaForm.name });
            } else {
              await this.$axios.post('/api/area', {
                name: this.areaForm.name,
                traceabilityId: this.selectedTraceabilityId
              });
            }
            this.fetchAreasOfTraceability();
          }
          this.toggleAddAreaModal();
        }
      } catch (error) {
        console.log(error);
      }
      this.isSaving = false;
    },
    toggleAddAreaModal(isAreaPage = false) {
      this.showAddAreaModal = !this.showAddAreaModal;
      this.isAddAreaPage = isAreaPage;
      this.isEditAreaPage = false;
      this.areaForm = { name: '', factory: '' };
      this.errors = [];
      this.selectedArea = null;
    },
    toggleAddPoolModal() {
      this.showAddPoolModal = !this.showAddPoolModal;
      this.errors = [];
      this.poolForm = {
        areaId: '',
        type: '',
        farmer: '',
        cropType: '',
        cropVariety: '',
        traySize: '',
        harvestTarget: '',
        eTransplantDate: '',
        eHarvestDate: '',
        transplantDate: '',
        harvestDate: '',
        harvestType: '',
        price: '',
        notes: '',
        isSelectedType: false,
      };
      this.selectedPool = null;
    },
    onChangeAreaForm(e) {
      // reset error when change
      this.errors = this.errors.filter(item => item !== e);
    },
    onChangePoolForm(e) {
      // reset error when change
      this.errors = this.errors.filter(item => item !== e);
    },
  },
};
</script>

<style lang="scss">
.select-path {
  .v-text-field__details {
    display: none;
  }

  .v-icon.v-icon {
    top: 6px;
  }
}
</style>