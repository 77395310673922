<template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col cols="3">
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          offset-y
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start"
            @input="startMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          offset-y
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="end" @input="endMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" @click="getDeviceLogs">
          <span v-if="!loading"> Filter </span>
          <v-progress-circular
            v-else
            indeterminate
            size="20"
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="align-center mb-6" cols="12">
        <div v-if="devices">
          <h3>GreenOS GPT</h3>
          <div class="chart">
            <canvas id="deviceChart" class="chart-canvas" height="100"></canvas>
          </div>
          <v-progress-circular
            class="d-flex justify-center mb-6"
            v-if="!isChartLoaded"
            indeterminate
            color="green"
          ></v-progress-circular>

          <br />

          <v-btn
            v-if="devices && devices.length > 0"
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs py-5 px-6 mt-6 mb-8 me-2"
            :style="{ background: 'linear-gradient(45deg, #4CAF50, #9C27B0)' }"
            color="primary"
            :loading="loading"
            @click="sendData(devices)"
          >
            <v-icon left>mdi-atom</v-icon>
            <span v-if="!loading" class="font-weight-bold"
              >Analyze with AI</span
            >
            <span v-else>
              <v-progress-circular
                indeterminate
                size="20"
                color="white"
              ></v-progress-circular>
            </span>
          </v-btn>

          <div v-if="responseMessage" class="response-message-container">
            <strong>GreenOS GPT:</strong>
            <div v-html="responseMessageHTML"></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { marked } from "marked";
import Chart from "chart.js/auto";
import moment from "moment";
import "chartjs-adapter-moment";

export default {
  name: "Profile-Overview",
  data() {
    return {
      devices: [],
      responseMessage: "",
      loading: false,
      isChartLoaded: false,
      greenTones: ["#4CAF50", "#83B582", "#3A6351"],
      startMenu: false,
      endMenu: false,
      start: null,
      end: null,
      chart: null,
    };
  },
  computed: {
    responseMessageHTML() {
      return marked(this.responseMessage, { sanitize: true });
    },
  },
  methods: {
    getColor(index) {
      const color = this.greenTones[index % this.greenTones.length];
      return color;
    },
    async sendData(data) {
      this.loading = true;

      const payload = {
        devices: data,
      };

      try {
        const response = await this.$axios.post("/api/chat/analyze", payload);

        this.responseMessage = response.data.content;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getDeviceLogs() {
      this.loading = true;
      let now = moment();
      let start = this.start
        ? moment(this.start).format("YYYY-MM-DD HH:mm:ss")
        : now.subtract(2, "years").format("YYYY-MM-DD HH:mm:ss");
      let end = this.end
        ? moment(this.end).format("YYYY-MM-DD HH:mm:ss")
        : moment().format("YYYY-MM-DD HH:mm:ss");

      const response = await this.$axios.get(
        `/api/report?start=${start}&end=${end}`
      );

      this.devices = response.data.devices;

      const colorMap = {
        Temperature: "rgba(255, 0, 0, 0.2)", // Kırmızı
        Humidity: "rgba(0, 0, 255, 0.2)", // Mavi
        CO2: "rgba(255, 165, 0, 0.2)", // Turuncu
      };

      const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color + "0.2";
      };

      const deviceNames = this.devices.map((device) => device.name);
      const datasets = [];

      deviceNames.forEach((deviceName) => {
        const deviceData = this.devices.find(
          (device) => device.name === deviceName
        ).data;

        const deviceValues = deviceData.map((datum) => ({
          x: moment(datum.timestamp).format("YYYY-MM-DD HH:mm:ss"),
          y: parseFloat(datum.value),
        }));

        const labelColor = colorMap[deviceName] || getRandomColor();

        datasets.push({
          label: deviceName,
          data: deviceValues,
          backgroundColor: labelColor,
          fill: false, // set this to false to create a line chart instead of a bar chart
        });
      });

      if (this.chart) {
        this.chart.destroy();
      }

      var ctx = document.getElementById("deviceChart").getContext("2d");

      this.chart = new Chart(ctx, {
        type: "line", // change this from 'bar' to 'line'
        data: {
          datasets: datasets,
        },
        options: {
          tooltips: {
            displayColors: true,
            callbacks: {
              mode: "index",
            },
          },
          responsive: true,
          scales: {
            x: {
              type: "category",
              distribution: "linear",
              time: {
                parser: "YYYY-MM-DD HH:mm:ss",
                unit: "second",
                displayFormats: {
                  second: "YYYY-MM-DD HH:mm:ss",
                },
              },
              ticks: {
                autoSkip: true,
              },
            },
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          legend: { position: "bottom" },
        },
      });
      this.loading = false;
    },
  },
  async mounted() {
    try {
      this.isChartLoaded = false;

      await this.getDeviceLogs();

      this.isChartLoaded = true;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style scoped lang="scss">
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  border-radius: 50%;
  width: 10em;
  height: 10em;
  border: 1.1em solid rgba(127, 127, 127, 0.2);
  border-left-color: #0f3e75;
  animation: spinner 1.1s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.response-message-container {
  margin-top: 20px;
}
</style>
