<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col lg="4" sm="8">
        <v-card>
          <div class="px-5 pt-2 text-center">
            LED Lightning
            <div class="px-16 pt-10 text-center">
              <v-row class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="15"
                  :value="50"
                  color="orange"
                >
                  {{ 50 }}
                </v-progress-circular></v-row
              >
            </div>
          </div>
          <div class="px-16 pt-10 text-center"></div>
        </v-card>
      </v-col>
      <v-col lg="4" sm="8">
        <v-card>
          <div class="px-5 pt-2 text-center">
            Water Filtration
            <div class="px-16 pt-10 text-center">
              <v-row class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="15"
                  :value="80"
                  color="blue"
                >
                  {{ 80 }}
                </v-progress-circular></v-row
              >
            </div>
          </div>
          <div class="px-16 pt-10 text-center"></div>
        </v-card>
      </v-col>
      <v-col lg="4" sm="8">
        <v-card>
          <div class="px-5 pt-2 text-center">
            Cleaning
            <div class="px-16 pt-10 text-center">
              <v-row class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="15"
                  :value="30"
                  color="green"
                >
                  {{ 30 }}
                </v-progress-circular></v-row
              >
            </div>
          </div>
          <div class="px-16 pt-10 text-center"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: function () {
    return {};
  },
};
</script>
