<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col lg="12" cols="12">
          <v-card class="border-radius-xl card-shadow">
            <div class="d-flex pb-0 px-4 py-4">
              <v-tabs v-model="tab" right class="overflow-hidden">
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1"> Analysis </v-tab>

                <v-tab href="#tab-2"> Activity </v-tab>

                <v-tab href="#tab-3"> Production </v-tab>
              </v-tabs>
            </div>

            <div class="px-0 py-2 mt-1">
              <v-tabs-items v-model="tab">
                <v-tab-item key="1" value="tab-1">
                  <div class="height-800 border-radius-lg">
                    <v-col md="12" cols="12">
                      <v-row justify="space-around">
                        <v-col cols="6" sm="4" md="8">
                          <div>
                            <v-alert color="primary" border="left" elevation="2" colored-border icon="mdi-chart-line">
                              <strong>
                                Which data do you want to visualize? and When?
                              </strong>
                            </v-alert>
                          </div>

                          <v-container class="transparent">
                            <v-col lg="12" cols="12">
                              <v-row class="mb-2" no-gutters>
                                <v-col cols="12" sm="8">
                                  <v-combobox v-model="selectedDevices" :items="devices" multiple chips item-text="name"
                                    item-value="_id" label="Select devices">
                                    <template v-slot:selection="{ attrs, item, selected }">
                                      <v-chip v-bind="attrs" :input-value="selected" close
                                        @click:close="removeDepartment(item._id)">
                                        {{item.name}}
                                      </v-chip>
                                    </template>
                                  </v-combobox>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-container>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-menu v-model="menu" ref="menu" :close-on-content-click="false" :return-value.sync="dates"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field value="dates" v-model="dateRangeText" range label="Date Range"
                                prepend-icon="mdi-calendar" persistent-hint readonly v-bind="attrs" v-on="on" clearable>
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range color="accent" :max="maxDate" :show-current="false">
                              <v-spacer></v-spacer>
                              <div>
                                <div class="d-flex align-center">
                                  <v-btn text color="primary" :ripple="false" class="text-capitalize" outlined small
                                    @click="select1Day">
                                    Last 1 day
                                  </v-btn>
                                  <v-btn text color="primary" :ripple="false" class="mx-2 text-capitalize" small
                                    outlined @click="select7Days">
                                    Last 7 days
                                  </v-btn>
                                  <v-btn text color="primary" outlined :ripple="false" class="text-capitalize" small
                                    @click="select30Days">
                                    Last 30 days
                                  </v-btn>
                                </div>
                                <div class="d-flex my-4 justify-end">
                                  <v-btn contained @click="menu = false" :ripple="false" small>
                                    Cancel
                                  </v-btn>
                                  <v-btn color="primary" medium class="ml-2" :ripple="false" small
                                    @click="$refs.menu.save(dates), show()">
                                    OK
                                  </v-btn>
                                </div>
                              </div>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>


                      <v-alert :value="showCharts" v-for="device in selectedDevices" :key="device._id" color="primary"
                        dark outlined transition="scale-transition">
                        <div :id="device._id"></div>
                      </v-alert>
                    </v-col>
                  </div>
                </v-tab-item>

                <v-tab-item key="2" value="tab-2">
                  <div>
                    <v-container fluid class="py-6">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-card class="card-shadow border-radius-xl mb-30">
                            <div class="card-header-padding">
                              <h6 class="font-weight-bold text-h6 text-typo mb-0">
                                Timeline
                              </h6>
                            </div>
                            <v-card-text class="card-padding px-0 pt-0">
                              <v-timeline dense align-top class="timeline-line-color">
                                <v-timeline-item v-for="(item, i) in timeline" :key="item.title + i" small
                                  class="timeline">
                                  <template v-slot:icon>
                                    <v-avatar size="26" color="#ffffff">
                                      <v-icon :class="
                                        `text-gradient text-` + item.btn
                                      " size="16">{{ item.icon }}</v-icon>
                                    </v-avatar>
                                  </template>

                                  <v-card width="480">
                                    <v-card-title class="px-0 pt-0 pb-1 d-block">
                                      <h6 class="
                                          text-dark text-sm
                                          font-weight-bold
                                          mb-0
                                          d-block
                                        ">
                                        {{ item.title }}
                                      </h6>
                                      <p class="
                                          text-secondary
                                          font-weight-bold
                                          text-xs
                                          mb-0
                                        ">
                                        {{ item.date }}
                                      </p>
                                    </v-card-title>
                                    <v-card-text class="px-0">
                                      <p class="text-sm mt-3 mb-2 text-body">
                                        People care about how you see the world,
                                        how you think, what motivates you, what
                                        you’re struggling with or afraid of.
                                      </p>
                                      <div class="mt-3">
                                        <v-btn :v-if="item.badges" v-for="item2 in item.badges" :key="item2.text"
                                          elevation="0" small :ripple="false" height="21" class="
                                            border-radius-sm
                                            text-xxs text-white
                                            me-1
                                            shadow-none
                                            font-weight-bold
                                            px-3
                                            py-1
                                            badge-font-size
                                          " :class="`bg-gradient-` + item.btn">{{ item2.text }}</v-btn>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-timeline-item>
                              </v-timeline>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-card class="
                              card-shadow
                              border-radius-xl
                              mb-30
                              bg-gradient-default
                            ">
                            <div class="card-header-padding">
                              <h6 class="font-weight-bold text-h6 text-white mb-0">
                                Timeline Dark
                              </h6>
                            </div>
                            <v-card-text class="card-padding px-0 pt-0">
                              <v-timeline dense align-top class="timeline-line-color">
                                <v-timeline-item v-for="(item, i) in timeline" :key="item.title + i" small
                                  class="timeline">
                                  <template v-slot:icon>
                                    <v-avatar size="26" color="#344767">
                                      <v-icon :class="
                                        `text-gradient text-` + item.btn
                                      " size="16">{{ item.icon }}</v-icon>
                                    </v-avatar>
                                  </template>

                                  <v-card width="480" color="transparent">
                                    <v-card-title class="px-0 pt-0 pb-1 d-block">
                                      <h6 class="
                                          text-white text-sm
                                          font-weight-bold
                                          mb-0
                                          d-block
                                        ">
                                        {{ item.title }}
                                      </h6>
                                      <p class="
                                          text-secondary
                                          font-weight-bold
                                          text-xs
                                          mb-0
                                        ">
                                        {{ item.date }}
                                      </p>
                                    </v-card-title>
                                    <v-card-text class="px-0">
                                      <p class="text-sm mt-3 mb-2 text-secondary">
                                        People care about how you see the world,
                                        how you think, what motivates you, what
                                        you’re struggling with or afraid of.
                                      </p>
                                      <div class="mt-3">
                                        <v-btn :v-if="item.badges" v-for="item2 in item.badges" :key="item2.text"
                                          elevation="0" small :ripple="false" height="21" class="
                                            border-radius-sm
                                            text-xxs text-white
                                            me-1
                                            shadow-none
                                            font-weight-bold
                                            px-3
                                            py-1
                                            badge-font-size
                                          " :class="`bg-gradient-` + item.btn">{{ item2.text }}</v-btn>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-timeline-item>
                              </v-timeline>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-tab-item>
                <v-tab-item key="3" value="tab-3">
                  <v-row>
                    <v-col lg="3" md="6" cols="12">
                      <v-card class="
                          card-shadow
                          bg-gradient-secondary
                          border-radius-xl
                          px-4
                          py-4
                        ">
                        <v-row no-gutters>
                          <v-col sm="8">
                            <p class="
                                text-white text-sm
                                mb-0
                                text-capitalize
                                font-weight-bold
                                opacity-7
                              ">
                              Harvest to Date
                            </p>
                            <h5 class="text-h5 text-white font-weight-bolder mb-0">
                              145 head
                            </h5>
                          </v-col>
                          <v-col sm="4" class="text-end">
                            <v-avatar color="bg-white" class="shadow" rounded>
                              <v-icon size="20" class="text-typo">
                                mdi-leaf
                              </v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col lg="3" md="6" cols="12">
                      <v-card class="
                          card-shadow
                          bg-gradient-secondary
                          border-radius-xl
                          px-4
                          py-4
                        ">
                        <v-row no-gutters>
                          <v-col sm="8">
                            <p class="
                                text-white text-sm
                                mb-0
                                text-capitalize
                                font-weight-bold
                                opacity-7
                              ">
                              Total Harvest Cycle
                            </p>
                            <h5 class="text-h5 text-white font-weight-bolder mb-0">
                              10
                            </h5>
                          </v-col>
                          <v-col sm="4" class="text-end">
                            <v-avatar color="bg-white" class="shadow" rounded>
                              <v-icon size="20" class="text-typo">
                                mdi-flag-checkered
                              </v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col lg="3" md="6" cols="12">
                      <v-card class="
                          card-shadow
                          bg-gradient-secondary
                          border-radius-xl
                          px-4
                          py-4
                        ">
                        <v-row no-gutters>
                          <v-col sm="8">
                            <p class="
                                text-white text-sm
                                mb-0
                                text-capitalize
                                font-weight-bold
                                opacity-7
                              ">
                              Total Revenue
                            </p>
                            <h5 class="text-h5 text-white font-weight-bolder mb-0">
                              100 $
                            </h5>
                          </v-col>
                          <v-col sm="4" class="text-end">
                            <v-avatar color="bg-white" class="shadow" rounded>
                              <v-icon size="20" class="text-typo">
                                mdi-cash-multiple
                              </v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col lg="3" md="6" cols="12">
                      <v-card class="
                          card-shadow
                          bg-gradient-secondary
                          border-radius-xl
                          px-4
                          py-4
                        ">
                        <v-row no-gutters>
                          <v-col sm="8">
                            <p class="
                                text-white text-sm
                                mb-0
                                text-capitalize
                                font-weight-bold
                                opacity-7
                              ">
                              Total Customer
                            </p>
                            <h5 class="text-h5 text-white font-weight-bolder mb-0">
                              100
                            </h5>
                          </v-col>
                          <v-col sm="4" class="text-end">
                            <v-avatar color="bg-white" class="shadow" rounded>
                              <v-icon size="20" class="text-typo">
                                mdi-account-group
                              </v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <datatable-simple></datatable-simple>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import DatatableSimple from "./Applications/Widgets/DatatableSimple.vue";

export default {
  name: "datatables",
  components: {
    DatatableSimple,
  },

  data: () => ({
    dates: [
      dayjs().subtract(1, 'd').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ],
    menu: false,
    temp: false,
    hum: false,
    co2: false,
    power: false,
    tab: null,
    hello: null,
    showCharts: false,
    devices: [],
    maxDate: new Date().toISOString(),
    selectedDevices: [],
    timeline: [
      {
        title: "$2400, Design changes",
        date: "22 DEC 7:20 PM",
        color: "#b0eed3",
        iconColor: "#1aae6f",
        icon: "ni-bell-55",
        btn: "success",
        badges: [
          {
            text: "desgin",
          },
        ],
      },
      {
        title: "New order #1832412",
        date: "21 DEC 11 PM",
        color: "#fdd1da",
        iconColor: "#f80031",
        icon: "ni-html5",
        btn: "danger",
        badges: [
          {
            text: "order",
          },
          {
            text: "#1832412",
          },
        ],
      },
    ],
  }),

  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    submittableDateTime() {
      const hello = new Date(this.dates[0]).toISOString().substr(0, 10);
      return hello;
    },

    submittableDateTime2() {
      const hello2 = new Date(this.dates[1]).toISOString().substr(0, 10);
      return hello2;
    },


  },

  mounted() {
    this.getDevices();
  },

  watch: {
    selectedDevices() {
      this.showCharts = false;
    }
  },

  methods: {
    select1Day() {
      this.menu = false;
      this.dates = [
        dayjs().subtract(1, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ];
    },
    select7Days() {
      this.menu = false;
      this.dates = [
        dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ];
    },
    select30Days() {
      this.menu = false;
      this.dates = [
        dayjs().subtract(30, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ];
    },
    async getDevices() {
      try {
        const { data: normalDevices } = await this.$axios.get('/api/devices?type=normal');
        const { data: advancedDevices } = await this.$axios.get('/api/devices?type=advanced');
        console.log({ normalDevices, advancedDevices });
        this.devices = [...normalDevices, ...advancedDevices];
      } catch (error) {
        console.log('getDevices error', error);
      }
    },
    show: function () {
      this.showCharts = true;

      this.$nextTick(() => {
        const sdk = new ChartsEmbedSDK({
          baseUrl: "https://charts.mongodb.com/charts-project-0-zcktu",
        });

        this.selectedDevices.forEach(dev => {
          const chart = sdk.createChart({
            chartId: dev._id,
            showAttribution: false,
            height: "480px",
            width: "auto",
            maxDataAge: 60,
            filter: {
              updatedAt: {
                $gte: new Date(this.submittableDateTime),
                $lt: new Date(this.submittableDateTime2),
              },
            },
          });
          chart.render(document.getElementById(dev._id));
        });
      });
    },
  },
};
</script>
