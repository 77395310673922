<template>
  <div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <v-container fluid>
            <v-row>
              <v-dialog v-model="dialog" persistent max-width="1000px">
                <template v-slot:activator="{ on }"> </template>
                <v-card>
                  <v-form
                    @submit.prevent="savePost"
                    ref="postData"
                    lazy-validation
                    enctype="multipart/form-data"
                    autocomplete="off"
                  >
                    <v-card-title>
                      <span class="headline">Posts</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Farmer*"
                              v-model="postData.farmer"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Crop Type*"
                              v-model="postData.cropType"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Crop Variety*"
                              v-model="postData.cropVariety"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Tray Size*"
                              v-model="postData.traySize"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Harvest Target*"
                              v-model="postData.harvestTarget"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Expected Transplant Date*"
                              v-model="postData.eTransplantDate"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Expected Harvest Date*"
                              v-model="postData.eHarvestDate"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Transplant Date"
                              v-model="postData.transplantDate"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Harvest Date"
                              v-model="postData.harvestDate"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Harvest Type"
                              v-model="postData.harvestType"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Price"
                              v-model="postData.price"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              label="Notes"
                              v-model="postData.notes"
                              color="#2C3A47"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#2C3A47" dark @click="close" outlined small>
                        <v-icon left>mdi-close</v-icon>Close
                      </v-btn>
                      <v-btn
                        color="light-green darken-4"
                        dark
                        type="submit"
                        outlined
                        small
                      >
                        <v-icon left>mdi-check-all</v-icon> {{ saveDialog }}
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
              <v-col>
                <v-card class="pa-2">
                  <v-container>
                    <v-card class="mx-auto elevation-0">
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify-plus"
                          label=""
                          single-line
                          hide-details
                          color="#2C3A47"
                        >
                        </v-text-field>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        :headers="headers"
                        :items="posts"
                        :search="search"
                        loading
                        loading-text="Loading... Please wait"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            color="green"
                            class="mx-2"
                            fab
                            x-small
                            @click="editPost(item)"
                            outlined
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn
                            color="pink"
                            fab
                            x-small
                            @click="deletePost(item._id)"
                            outlined
                          >
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn color="blue" class="mx-2" fab x-small outlined>
                            <v-icon small>mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:no-results>
                          <v-alert
                            :value="true"
                            color="pink"
                            icon="mdi-alert"
                            dark
                          >
                            Your Search {{ search }} does not exist.
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-container>
                </v-card>
                <v-snackbar v-model="snackbar" top right :color="color">
                  {{ text }}
                  <v-btn color="black" text @click="snackbar = false">
                    Okay
                  </v-btn>
                </v-snackbar>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>

export default {
  props: {
    source: String,
  },
  data: () => ({
    mini: false,
    dialog: false,
    fab: false,
    search: "",
    snackbar: false,
    text: "",
    color: "",
    posts: [],
    headers: [
      { text: "Crop Type", value: "cropType", sortable: true },
      { text: "Crop Variety", value: "cropVariety", sortable: true },
      { text: "Tray Size", value: "traySize", sortable: false },
      { text: "Harvest Target", value: "harvestTarget", sortable: false },
      { text: "Seedling Date", value: "seedDate", sortable: true },
      {
        text: "Expected Transplant Date",
        value: "eTransplantDate",
        sortable: false,
      },
      { text: "Expected Harvest Date", value: "eHarvestDate", sortable: false },
      { text: "Transplant Date", value: "transplantDate", sortable: false },
      { text: "Action", value: "actions", sortable: true, width: "180px" },
    ],
    postData: {
      farmer: "",
      cropType: "",
      cropVariety: "",
      traySize: "",
      harvestTarget: "",
      seedDate: "",
      eTransplantDate: "",
      eHarvestDate: "",
      transplantDate: "",
      harvestDate: "",
      harvestType: "",
      price: "",
      notes: "",
    },
    default: {
      farmer: "",
      cropType: "",
      cropVariety: "",
      traySize: "",
      harvestTarget: "",
      seedDate: "",
      eTransplantDate: "",
      eHarvestDate: "",
      transplantDate: "",
      harvestDate: "",
      harvestType: "",
      price: "",
      notes: "",
    },
    editedIndex: -1,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    saveDialog() {
      return this.editedIndex === -1 ? "Save" : "Edit";
    },
  },
  mounted() {
    this.loadPosts();
  },
  methods: {
    loadPosts: async function () {
      let apiURL = "http://localhost:4000/api";
      axios
        .get(apiURL)
        .then((res) => {
          this.posts = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.postData = Object.assign({}, this.default);
        this.editedIndex = -1;
      }, 300);
    },
    savePost: async function () {
      if (this.editedIndex > -1) {
        this.updatePost();
      } else {
        this.createPost();
      }
    },
    createPost() {
      let apiURL = "http://localhost:4000/api/create-post";
      axios
        .post(apiURL, this.postData)
        .then(() => {
          this.postData = {
            farmer: "",
            cropType: "",
            cropVariety: "",
            traySize: "",
            harvestTarget: "",
            seedDate: "",
            eTransplantDate: "",
            eHarvestDate: "",
            transplantDate: "",
            harvestDate: "",
            harvestType: "",
            price: "",
            notes: "",
          };
          this.close();
          this.loadPosts();
          this.color = "success";
          this.text = "Post has been successfully saved.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editPost(item) {
      this.editedIndex = this.posts.indexOf(item);
      this.postData = Object.assign({}, item);
      this.dialog = true;
    },
    updatePost() {
      let apiURL = `http://localhost:4000/api/update-post/${this.postData._id}`;
      axios
        .post(apiURL, this.postData)
        .then((res) => {
          console.log(res);
          this.close();
          this.loadPosts();
          this.color = "info";
          this.text = "Post has been modified.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          console.log(this.$route.params.id);
        });
    },
    deletePost(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to go back",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00b894",
        cancelButtonColor: "#d63031",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          try {
            let apiURL = `http://localhost:4000/api/delete-post/${id}`;
            let indexOfArrayItem = this.posts.findIndex((i) => i._id === id);
            axios
              .delete(apiURL)
              .then(() => {
                this.posts.splice(indexOfArrayItem, 1);
              })
              .catch((error) => {
                console.log(error);
              });
            this.$swal("Deleted!", "Post has been deleted.", "success");
            //Notification error
            this.color = "error";
            this.text = "Post has been deleted.";
            this.snackbar = true;
            //Notification error
          } catch (error) {
            this.$swal("Failed!", "There was something wrong.", "warning");
          }
        }
      });
    },
  },
};
</script>
