<template>
  <v-container class="transparent">
    <v-btn @click="toggleAddModal" outlined elevation="0" :ripple="false" height="43" class="
          font-weight-bold
          text-capitalize
          px-6
        ">
      Add Line or Zone
    </v-btn>
    <v-dialog v-model="showAddForm" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            {{editLineId ? 'Edit Line' : 'Add New Line or Zone'}}
          </span>
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="factoryName" autofocus hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" placeholder="Line or Zone Name" />
                <span class="text-danger" v-if="errorMsg != null && !isLoading">
                  {{errorMsg}}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn @click="toggleAddModal" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-gradient-light
                py-3
                px-6
              ">Cancel</v-btn>

          <v-btn @click="addNewFactory" elevation="0" :loading="isLoading" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-gradient-primary
                py-3
                px-6
              ">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-row class="mb-0 mt-2">
        <v-col v-for="(item, i) in factories" :key="i" class="pa-2 list-item-hover-active" md="4" sm="6" cols="12">
          <v-card class="px-3 py-2 border-radius-xl">
            <div class="d-flex align-center justify-space-between overflow-hidden line-card">
              <div @click="navigateTo(item)">
                <p class="text-secondary text-sm mb-0">
                  Line - {{i+1}}
                </p>
                <h5 class="text-h5 font-weight-bolder mb-0">
                  {{item.name}}
                </h5>
              </div>
              <div class="d-flex justify-end hover-act" style="gap: 8px">
                <div class="border-radius-md bg-gradient-primary icon-wrap" @click="navigateTo(item)">
                  <v-icon color="white" height="24">mdi-office-building</v-icon>
                </div>
                <div class="border-radius-md bg-gradient-default icon-wrap" @click="editLine(item)">
                  <v-icon color="white" height="24">mdi-pencil</v-icon>
                </div>
                <div class="border-radius-md bg-gradient-default icon-wrap" @click="deleteLine(item)">
                  <v-icon color="white" height="24" v-if="deletingLineId != item._id">mdi-delete</v-icon>
                  <v-progress-circular v-else color="white" size="20" indeterminate />
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Factory',
  components: {
  },

  data() {
    return {
      showAddForm: false,
      errorMsg: null,
      factoryName: '',
      isLoading: false,
      factories: [],
      editLineId: null,
      deletingLineId: null,
    };
  },

  mounted() {
    this.fetchFactories();
  },

  methods: {
    toggleAddModal() {
      this.showAddForm = !this.showAddForm;
      this.factoryName = '';
      this.errorMsg = '';
      this.editLineId = null;
      this.deletingLineId = null;
    },

    navigateTo(item) {
      this.$router.push('/pages/dashboards/factory/' + item._id + '?name=' + item.name);
    },

    async fetchFactories() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get('/api/factory');
        this.factories = data;
      } catch (error) { }
      this.isLoading = false;
    },

    async addNewFactory() {
      if (this.factoryName.trim().length === 0 || this.isLoading) {
        this.errorMsg = 'Name is required!';
        return;
      }
      this.isLoading = true;
      try {
        if (this.editLineId) {
          await this.$axios.put('/api/factory/' + this.editLineId, { name: this.factoryName });
        } else {
          await this.$axios.post('/api/factory', { name: this.factoryName });
        }
        this.fetchFactories();
        this.toggleAddModal();
      } catch (error) {
        this.errorMsg = this.editLineId ? "Edit failed" : "Can't create factory!";
      }
      this.isLoading = false;
    },

    editLine(item) {
      this.editLineId = item._id;
      this.factoryName = item.name;
      this.showAddForm = true;
    },

    async deleteLine(item) {
      if (!this.deletingLineId) {
        this.deletingLineId = item._id;
        try {
          await this.$axios.delete('/api/factory/' + item._id);
          this.fetchFactories();
        } catch (error) {
          console.log(error);
        }
        this.deletingLineId = null;
      }
    },
  },
};
</script>

<style lang="scss">
.line-card {
  position: relative;

  .hover-act {
    position: absolute;
    right: -80px;
    top: 10px;
    transition: all 0.3s;
  }

  &:hover .hover-act {
    right: 10px;
  }
}

.icon-wrap {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
