<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col lg="4">
          <v-card class="card-shadow border-radius-xl">
            <v-row fluid class="px-10 py-3">
            <v-file-input
    show-size
    counter
    multiple
    label="File input"
  ></v-file-input>
</v-row>
            <div class="px-4 pt-4 d-flex align-center">
              <h6 class="mb-0 text-h6 font-weight-bold text-typo">Documents</h6>
            </div>
            <div class="px-4 pb-2 pt-2">
              <div v-for="invoice in invoices" :key="invoice.date">
                <v-list-item
                  :key="invoice.id"
                  class="px-0 py-3 justify-between"
                >
                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark font-weight-bold text-sm">
                        {{ invoice.id }}
                      </h6>
                      <span class="text-xs text-body">{{ invoice.date }}</span>
                    </div>
                  </v-list-item-content>

                  <v-list-item-content class="py-0 text-end">
                    <div class="d-flex align-center text-sm text-body">
                      <span class="ms-auto"></span>
                      <v-btn
                        color="#fff"
                        class="text-dark font-weight-bolder py-6 px-5 shadow-0"
                        small
                        simple
                      >
                        <v-icon size="18" class="rotate-180"
                          >fas fa-file-pdf text-lg me-1</v-icon
                        >
                        Pdf
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col lg="8" md="8">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-4 pt-4 d-flex align-center">
              <h6 class="mb-0 text-h6 font-weight-bold text-typo">FAQ</h6>
            </div>
            <div class="px-4 pt-4 d-flex align-center">
              <v-expansion-panels inset>
                <v-expansion-panel v-for="item in items" :key="i">
                  <v-expansion-panel-header>{{
                    item.header
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="px-4 pt-4 d-flex align-center"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Billing",
  data: function () {
    return {
      invoices: [
        {
          date: "March, 01, 2021",
          id: "#MCC Panel Project",
        },
        {
          date: "February, 10, 2020",
          id: "#Ph Calibration",
        },
        {
          date: "April, 05, 2020",
          id: "#Ec Calibration",
        },
        {
          date: "June, 25, 2019",
          id: "#Warranty Certificate",
        },
        {
          date: "March, 01, 2019",
          id: "#Reverse-Osmos",
        },
      ],
      items: [
        {
          header: "pH doesnt change, why?",
          answer: "#MCC Panel Project",
        },
        {
          header: "EC doesnt change, why?",
          answer: "#MCC Panel Project",
        },
        {
          header: "Container is very hot, why?",
          answer: "#MCC Panel Project",
        },
        {
          header: "Water pump doesnt work, why?",
          answer: "#MCC Panel Project",
        },
        {
          header: "Plants grow slowly, why?",
          answer: "#MCC Panel Project",
        },
      ],
    };
  },
};
</script>
