<template>
  <div>
    <v-container fluid class="py-6">
      <v-col md="12" cols="4">
        <v-card elevation="2" class="mx-auto pa-3 border-radius-xl card-shadow">
          <v-chip-group
            v-model="academyTitle"
            column
            multiple
            active-class="orange"
            color="orange"
          >
            <v-chip filter outlined> Gateways </v-chip>
            <v-chip filter outlined> Router </v-chip>
            <v-chip filter outlined> RMS </v-chip>
            <v-chip filter outlined> greenOS </v-chip>
            <v-chip filter outlined> Mqtt </v-chip>
          </v-chip-group>
        </v-card>
      </v-col>
      <v-row>
        <v-col md="6" cols="12">
          <v-card class="mx-auto pa-4 border-radius-xl card-shadow">
            <iframe
              width="525"
              height="315"
              src="https://www.youtube.com/embed/siT_QL6spEk"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> </v-card
        ></v-col>

        <v-col md="6" cols="12">
          <v-card class="mx-auto pa-4 border-radius-xl card-shadow">
            <iframe
              width="525"
              height="315"
              src="https://www.youtube.com/embed/Os4L3wDvAbY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> </v-card
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Academy",
  data() {
    return {};
  },
};
</script>
