<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2">
        GreenOS Digital Farm
      </h3>
      <p class="mb-0 text-body">Enter your email and password to register</p>
    </div>
    <div class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1">User Name</label>
      <v-text-field
        v-model="username"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Name"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
      <v-text-field
        v-model="email"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Email"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">Password</label>
      <v-text-field
        v-model="password"
        type="password"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Password"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>

      <v-btn
        @click="signup"
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          bg-gradient-primary
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        >Sign Up</v-btn
      >
      <div class="text-center">{{ error }}</div>
      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Already have an account?
          <a
            href="#/pages/authentication/signin/signin"
            class="
              text-primary text-gradient text-decoration-none
              font-weight-bold
            "
            >Sign in</a
          >
        </p>
      </div>
    </div>
  </v-card>
</template>
<script>

export default {
  name: "Signup",
  data() {
    return {
      username: "",
      password: "",
      email: "",
      error: "",
    };
  },
  methods: {
    signup() {
      let newUser = {
        username: this.username,
        email: this.email,
        password: this.password,
      };
      console.log(newUser),
        this.$axios.post("api/signup", newUser).then(
          (res) => {
            console.log(res);
            this.$router.push("/pages/authentication/signin/signin");
          },
          (err) => {
            console.log(err.response);
            this.error = err.response.data.error;
          }
        );
    },
  },
};
</script>
