<template>
  <v-container fluid class="">
    <v-row>
      <v-col lg="8" cols="12" class="mx-auto my-8">
        <v-card class="border-radius-xl card-shadow">
          <div class="px-4 py-3">
            <h5 class="font-weight-bolder text-center text-h5 text-typo mb-0">
              MQTT Setting
            </h5>
            <div class="mt-2">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Username</label
              >
              <v-text-field
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder="eg. Michael"
                class="
                  font-size-input
                  placeholder-lighter
                  text-light-input
                  border border-radius-md
                  mt-2
                "
                v-model="settingForm.username">
              </v-text-field>
              <span
                class="text-danger"
                v-for="item in error"
                v-if="item === 'username'"
                v-bind:key="item">
                Username not valid.
              </span>
            </div>
            <div class="mt-2">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Password</label
              >
              <v-text-field
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder="eg. 123123"
                class="
                  font-size-input
                  placeholder-lighter
                  text-light-input
                  border border-radius-md
                  mt-2
                "
                v-model="settingForm.password">
              </v-text-field>
              <span
                class="text-danger"
                v-for="item in error"
                v-if="item === 'password'"
                v-bind:key="item">
                Password not valid.
              </span>
            </div>

            <div class="mt-2">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Host address</label
              >
              <v-text-field
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder="Enter host address"
                class="
                  font-size-input
                  placeholder-lighter
                  text-light-input
                  border border-radius-md
                  mt-2
                "
                v-model="settingForm.url">
              </v-text-field>
              <span
                class="text-danger"
                v-for="item in error"
                v-if="item === 'url'"
                v-bind:key="item">
                Host address not valid.
              </span>
            </div>
          </div>
          <div class="text-end">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-default
                py-5
                px-6
                mt-6
                mb-2
                me-2
              "
              color="primary"
              v-on:click="saveSetting">
              {{ saving ? ' Saving...' : 'Save' }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showSnackbar"
      fixed
      bottom
      :color="saveStatus"
      outlined
      right>
      {{ message }}
    </v-snackbar>
  </v-container>
</template>
<script>

export default {
  name: 'Profile-Setting',

  data: () => ({
    error: [],
    settingForm: {
      username: '',
      password: '',
      url: '',
    },
    showSnackbar: false,
    saveStatus: '',
    message: '',
    saving: false,
  }),
  mounted() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      try {
        const { data } = await this.$axios.get('api/setting');
        this.settingForm = data;
      } catch (error) {
        console.log({ err });
      }
    },
    async saveSetting() {
      if (this.saving) {
        return;
      }
      // reset values
      this.error = [];
      this.showSnackbar = false;
      this.saveStatus = '';
      this.message = '';

      ['url', 'username', 'password'].forEach((item) => {
        if (
          this.settingForm[item] === '' ||
          this.settingForm[item].trim().length === 0
        ) {
          this.error.push(item);
        }
      });

      if (this.error.length === 0) {
        let isSuccess = true;
        this.saving = true;
        try {
          await this.$axios.put('api/setting/' + this.settingForm._id, {
            url: this.settingForm.url,
            username: this.settingForm.username,
            password: this.settingForm.password,
          });
        } catch (error) {
          isSuccess = false;
        }
        this.showSnackbar = true;
        this.saveStatus = isSuccess ? 'success' : 'error';
        this.message = isSuccess ? 'Save successfully' : 'Save failed';
        this.saving = false;
        setTimeout(() => {
          this.showSnackbar = false;
        }, 3000);
      }
    },
  },
};
</script>
