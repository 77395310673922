<template>
  <v-container fluid class="">
    <v-snackbar
      v-if="userInvited === true"
      :timeout="2000"
      :value="true"
      rounded="pill"
      centered
      color="green"
    >
      {{ showMessage }}
    </v-snackbar>
    <v-row>
      <v-col lg="12" cols="10" class="mx-auto">
        <v-card style="padding: 25px">
          <div>
            <h5 class="font-weight-bolder text-h5 text-typo mb-0">
              Invite user
            </h5>
            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >First Name</label
                >
                <v-text-field
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Michael"
                  class="
                    font-size-input
                    placeholder-lighter
                    text-light-input
                    border border-radius-md
                    mt-2
                  "
                  v-model="inviteForm.first_name"
                >
                </v-text-field>
                <span
                  class="text-danger"
                  v-for="item in error"
                  v-if="item === 'first_name'"
                  v-bind:key="item"
                >
                  First name is required.
                </span>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Last Name</label
                >
                <v-text-field
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Prior"
                  class="
                    font-size-input
                    placeholder-lighter
                    text-light-input
                    border border-radius-md
                    mt-2
                  "
                  v-model="inviteForm.last_name"
                >
                </v-text-field>
                <span
                  class="text-danger"
                  v-for="item in error"
                  v-if="item === 'last_name'"
                  v-bind:key="item"
                >
                  Last name is required.
                </span>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Department</label
                >
                <v-select
                  :items="departments"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-2
                    mb-0
                  "
                  outlined
                  single-line
                  item-text="name"
                  item-value="_id"
                  hide-details
                  placeholder="Select department"
                  v-model="inviteForm.departmentId"
                >
                </v-select>
                <span
                  class="text-danger"
                  v-for="item in error"
                  v-if="item === 'departmentId'"
                  v-bind:key="item"
                >
                  Department is required.
                </span>
              </v-col>

              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Email Address</label
                >
                <v-text-field
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. soft@dashboard.com"
                  class="
                    font-size-input
                    placeholder-lighter
                    text-light-input
                    border border-radius-md
                    mt-2
                  "
                  v-model="inviteForm.email"
                >
                </v-text-field>
                <span
                  class="text-danger"
                  v-for="item in error"
                  v-if="item === 'email'"
                  v-bind:key="item"
                >
                  Email is required.
                </span>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >User Role</label
                >
                <v-select
                  :items="items"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-2
                    mb-0
                  "
                  outlined
                  single-line
                  height="25"
                  hide-details
                  placeholder="Select role"
                  v-model="inviteForm.user_role"
                ></v-select>
                <span
                  class="text-danger"
                  v-for="item in error"
                  v-if="item === 'user_role'"
                  v-bind:key="item"
                >
                  Please select user role.
                </span>
              </v-col>
            </v-row>
          </div>
          <div class="text-end">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-default
                py-5
                px-6
                mt-6
                mb-2
                me-2
              "
              color="primary"
              :loading="isSaving"
              @click="invitePeople"
            >
              Invite
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" cols="10" class="mx-auto my-4">
        <v-card style="padding: 25px">
          <div>
            <h5 class="font-weight-bolder text-h5 text-typo mb-0">
              Create Department
            </h5>
            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1">
                  Please enter which department you want to add.
                </label>
                <v-combobox
                  v-model="departments"
                  :loading="isSaving"
                  id="departmentInput"
                  :items="departments"
                  multiple
                  chips
                  hide-selected
                  item-text="name"
                  item-value="_id"
                  @change="departmentChange"
                >
                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click:close="removeDepartment(item._id)"
                    >
                      {{ item.name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      style="padding: 25px"
      :headers="headers"
      :items="userList"
      sort-by="calories"
      class=""
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h5">User List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.firstname"
                        hide-details
                        label="First name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.lastname"
                        hide-details
                        label="Last name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.email"
                        hide-details
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.departmentId"
                        hide-details
                        :items="departments"
                        item-text="name"
                        item-value="_id"
                        label="Department"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.user_role"
                        :items="items"
                        hide-details
                        label="User Role"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this User?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
const token = localStorage.getItem("token");

export default {
  name: "Profile-User",

  data: () => ({
    items: ["User", "Admin", "Demo"],
    departments: [],
    userDetails: "",
    userInvited: false,
    error: [],
    showMessage: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "First name",
        align: "start",
        sortable: false,
        value: "firstname",
      },
      { text: "Last name", value: "lastname" },
      { text: "Email", value: "email" },
      { text: "User role", value: "user_role" },
      { text: "Department", value: "department.name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    userList: [],
    inviteForm: {
      first_name: "",
      last_name: "",
      email: "",
      departmentId: "",
      user_role: "",
    },
    editedIndex: -1,
    editedItem: {
      first_name: "",
      last_name: "",
      email: "",
      departmentId: "",
      user_role: "",
    },
    defaultItem: {
      first_name: "",
      last_name: "",
      email: "",
      departmentId: "",
      user_role: "",
    },
    isSaving: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Edit User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    initialize() {
      console.log("initialize");
    },

    removeDepartment: async function (id) {
      this.isSaving = true;
      try {
        await this.$axios.delete("/api/department/" + id);
        this.departments = this.departments.filter((item) => item._id !== id);
        this.getUserList();
      } catch (error) {
        console.log("delete error", error);
      }
      this.isSaving = false;
    },

    departmentChange: async function () {
      const input = document.getElementById("departmentInput");
      this.departments = this.departments.filter(
        (item) => typeof item !== "string"
      );

      if (input.value.trim().length > 0) {
        this.isSaving = true;
        try {
          await this.$axios.post("/api/department", {
            name: input.value.trim(),
          });
          await this.getDepartments();
        } catch (error) {
          console.log(error);
        }
        this.isSaving = false;
      }
    },

    invitePeople: async function () {
      this.error = [];

      for (const item in this.inviteForm) {
        if (
          this.inviteForm[item] === "" ||
          this.inviteForm[item].length === 0
        ) {
          this.error.push(item);
        }
      }

      if (this.error.length === 0) {
        this.isSaving = true;
        try {
          const payload = {
            firstname: this.inviteForm.first_name,
            lastname: this.inviteForm.last_name,
            email: this.inviteForm.email,
            departmentId: this.inviteForm.departmentId,
            user_role: this.inviteForm.user_role,
          };
          await this.$axios.post("/api/user/invite", payload, {
            headers: {
              token,
              "Content-Type": "application/json",
            },
          });
          this.userInvited = true;
          this.showMessage = "User added successfully";
          this.inviteForm = {
            first_name: "",
            last_name: "",
            email: "",
            departmentId: "",
            user_role: "",
          };
        } catch (error) {
          if (error.response.data.error === "Email in use") {
            this.userInvited = true;
            this.showMessage = err.response.data.error;
          }
        }
        this.isSaving = false;
      }
    },

    editItem(item) {
      this.editedIndex = this.userList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      console.log("delete item", item);
      this.editedIndex = this.userList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$axios
        .delete("/api/user/" + this.editedItem._id, {
          headers: {
            token,
            "Content-Type": "application/json",
          },
        })
        .then(
          (res) => {
            this.$swal({
              title: `User deleted successfully`,
              // imageUrl: result.value.avatar_url,
            });
            this.getUserList();
          },
          (err) => {
            this.error = err.response.data.error;
          }
        );
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save: async function () {
      const payload = {
        firstname: this.editedItem.firstname,
        lastname: this.editedItem.lastname,
        departmentId: this.editedItem.departmentId,
        department: this.editedItem.departmentId,
        user_role: this.editedItem.user_role,
        email: this.editedItem.email,
      };

      try {
        const headers = { token, "Content-Type": "application/json" };
        await this.$axios.patch("/api/user/" + this.editedItem._id, payload, {
          headers,
        });
        this.$swal({
          title: `User updated successfully`,
        });
        this.getUserList();
      } catch (error) {
        this.error = error.response.data.error;
      }
      this.close();
    },

    getUserList() {
      const headers = { token };
      this.$axios.get("/api/user/users", { headers }).then(({ data }) => {
        this.userList = data.user;
      });
    },

    getProfile() {
      const headers = { token };
      this.$axios.get("/api/user/me", { headers }).then(({ data }) => {
        this.userDetails = data.user;
      });
    },

    getDepartments: async function () {
      try {
        const { data } = await this.$axios.get("/api/department");
        this.departments = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getProfile();
    this.getUserList();
    this.getDepartments();
  },
};
</script>
