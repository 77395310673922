<template>
  <div>
    <v-container fluid class="px-6 py-4">
      <v-row class="px-6 py-6">
        <v-btn :ripple="false" @click="toggleAddPageOrSensor(true)" height="43" class="
          font-weight-bold
          text-capitalize
          py-3
        ">
          <v-icon size="16">mdi-plus-thick</v-icon> Add 1/0 Page
        </v-btn>
        <v-btn :ripple="false" v-if="!!selectedNormalTabId" @click="editPageOrSensor(true)" height="43" class="
          font-weight-bold
          text-capitalize
          py-3
          ml-3
        ">
          <v-icon size="16">mdi-pencil</v-icon> Edit current page
        </v-btn>
        <v-btn :ripple="false" @click="deletePageOrSensor(selectedNormalTabId)" v-if="!!selectedNormalTabId"
          :loading="selectedNormalTabId === deletingCategoryId" height="43" class="
          font-weight-bold
          text-capitalize
          py-3
          ml-3
        ">
          <v-icon size="16">mdi-delete</v-icon> Delete current page
        </v-btn>
      </v-row>

      <v-dialog v-model="showAddPageOrSensor" max-width="500px">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-header-padding card-border-bottom">
            <span class="font-weight-bold text-h5 text-typo mb-0">
              {{this.isAddPage ? 'Add New Page' : 'Add New Sensor'}}
            </span>
          </div>
          <v-card-text class="card-padding">
            <v-container class="px-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="formData.name" hide-details autofocus class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" :placeholder="isAddPage ? 'Page name' : 'Sensor name'" />
                  <span class="text-danger" v-for="item in error" v-if="item === 'name'" v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="card-padding d-flex justify-end">
            <v-btn @click="toggleAddPageOrSensor(false)" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-gradient-light
                py-3
                px-6
              ">Cancel</v-btn>

            <v-btn @click="submitNewPageOrSensor" :loading="isSaving" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-gradient-primary
                py-3
                px-6
              ">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showAddDevice" max-width="768px">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-header-padding card-border-bottom">
            <span class="font-weight-bold text-h5 text-typo mb-0">
              {{selectedDeviceId ? 'Edit Device' : 'Add New Device'}}
            </span>
          </div>
          <v-card-text class="card-padding">
            <v-container class="px-0">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="formData.name" autofocus hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" placeholder="Name" />
                  <span class="text-danger" v-for="item in error" v-if="item === 'name'" v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete :items="allIcons" v-model="formData.icon" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      prepend-inner
                    " dense flat clearable height="43px" filled solo :prepend-inner-icon="formData.icon"
                    placeholder="Eg: mdi-account">
                    <template v-slot:item="data">
                      <div class="d-flex">
                        <v-icon>{{ data.item }}</v-icon>
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </div>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-for="item in error" v-if="item === 'icon'" v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>

                <v-col cols="6" v-if="isUnitForm">
                  <v-text-field v-model="formData.unit" hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" placeholder="Unit (Eg: pH)" />
                  <span class="text-danger" v-for="item in error" v-if="item === 'unit'" v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>
                <v-col cols="6" v-if="isUnitForm">
                  <v-text-field v-model="formData.divisionCoefficient" hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" placeholder="Coefficient (eg: *0,1)" type="number" min="1" />
                  <span class="text-danger" v-for="item in error" v-if="item === 'divisionCoefficient'"
                    v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>

                <v-col cols="12">
                  <v-text-field v-model="formData.topic" hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" placeholder="MQTT Topic" />
                  <span class="text-danger" v-for="item in error" v-if="item === 'topic'" v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="formData.numbers" hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" placeholder="Numbers" type="number" min="0" />
                  <span class="text-danger" v-for="item in error" v-if="item === 'numbers'" v-bind:key="item">
                    Field is required.
                  </span>
                </v-col>
                <div v-if="isUnitForm">
                  <v-col cols="12">
                    <v-row class="pb-3">
                      <v-col cols="1 pt-5">
                        <toggle-button v-model="formData.isSaveDB" color="#82C7EB" :labels="true" />
                      </v-col>
                      <v-col cols="3 pt-5">Save Database</v-col>
                      <v-col cols="2">
                        <v-text-field v-model="formData.saveDBAt" hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat
                          filled solo height="32" type="number" min="0" placeholder="eg: 1"
                          :disabled="!formData.isSaveDB" />
                        <span class="text-danger" v-for="item in error" v-if="item === 'saveDBAt'" v-bind:key="item">
                          Required
                        </span>
                      </v-col>
                      <v-col cols="2 pt-5">Minutes</v-col>
                    </v-row>
                  </v-col>
                  <v-row class="px-3">
                    <v-col cols="1 pt-5">
                      <toggle-button v-model="formData.isNotification" color="#82C7EB" :labels="true" />
                    </v-col>
                    <v-col cols="2 pt-5">Notification</v-col>
                    <v-col cols="1 pt-5">Min</v-col>
                    <v-col cols="2">
                      <v-text-field v-model="formData.minMQTTValue" hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat
                        filled solo height="32" type="number" min="0" placeholder="eg: 0" />
                      <span class="text-danger" v-for="item in error" v-if="item === 'minMQTTValue'" v-bind:key="item">
                        Required
                      </span>
                    </v-col>
                    <v-col cols="1 pt-5">Max</v-col>
                    <v-col cols="2">
                      <v-text-field v-model="formData.maxMQTTValue" hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon" dense flat
                        filled solo height="32" type="number" min="0" placeholder="eg: 1" />
                      <span class="text-danger" v-for="item in error" v-if="item === 'maxMQTTValue'" v-bind:key="item">
                        Required
                      </span>
                    </v-col>
                  </v-row>
                </div>

                <v-row class="px-3 py-3" v-else>
                  <v-col cols="1">
                    <toggle-button v-model="formData.isSaveDB" color="#82C7EB" :labels="true" />
                  </v-col>
                  <v-col cols="2">Save Database</v-col>
                  <v-col cols="1">
                    <toggle-button v-model="formData.isNotification" color="#82C7EB" :labels="true" />
                  </v-col>
                  <v-col cols="2">Notification</v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="card-padding d-flex justify-end">
            <v-btn @click="toggleAddDevice(false)" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-gradient-light
                py-3
                px-6
              ">Cancel</v-btn>

            <v-btn @click="submitDevice" :loading="isSaving" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-gradient-primary
                py-3
                px-6
              ">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="px-6 py-3">
        <h6 class="pt-12 text-muted text-center text-body-2" v-if="selectedNormalTabId === null">
          Let's create new page
        </h6>
        <h6 class="pt-12 text-muted text-center text-body-2" v-if="categoriesNormal.length === null">
          Let's create new device
        </h6>

        <v-tabs v-model="selectedNormalTabId" background-color="transparent" light
          next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" color="primary"
          show-arrows>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab v-for="category in categoriesNormal" :key="category._id" :href="`#${category._id}`">
            {{category.name}}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedNormalTabId" class="px-4 py-4">
          <v-tab-item v-for="category in categoriesNormal" :key="category._id" :value="category._id">

            <v-row>
              <v-col md="3" cols="12" sm="6" v-for="(device, i) in category.devices" :key="i">
                <v-card class="transparent device-wrap" :key="i">

                  <v-alert :value="true" :color="(+device.mqtt || 0) > 0 ? 'green' : 'gray'" border="left" elevation="2"
                    colored-border :icon="device.icon" class="
                      text-gray
                      pa-4
                      text-center
                      button
                      rounded-xl 
                      rounded-bl-0
                      mb-1
                    ">
                    <strong>{{device.name}}</strong>
                    <v-icon class="icon" @click="deleteDevice(device._id)">mdi-delete-outline</v-icon>
                    <v-icon class="icon icon-edit" @click="editDevice(device)">mdi-pencil-outline</v-icon>
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>

        <v-row class="px-6 py-6">
          <v-btn v-if="categoriesNormal.length > 0" @click="toggleAddDevice(false)" elevation="0" :ripple="false"
            height="43" class="
              font-weight-bold
              text-capitalize
              btn-primary
              bg-gradient-secondary
              py-3
            ">
            <v-icon size="16">mdi-plus-thick</v-icon> Add Device
          </v-btn>
        </v-row>
      </v-card>
    </v-container>

    <v-container fluid class="px-6 py-4">
      <v-row class="px-6 py-6">
        <v-btn :ripple="false" height="43" class="
          font-weight-bold
          text-capitalize
          py-3
        " @click="toggleAddPageOrSensor(false)">
          <v-icon size="16">mdi-plus-thick</v-icon> Add Sensor Page
        </v-btn>
        <v-btn :ripple="false" v-if="!!selectedAdvancedTabId" @click="editPageOrSensor(false)" height="43" class="
            font-weight-bold
            text-capitalize
            py-3
            ml-3
          ">
          <v-icon size="16">mdi-pencil</v-icon> Edit current sensor group
        </v-btn>
        <v-btn :ripple="false" @click="deletePageOrSensor(selectedAdvancedTabId)" v-if="!!selectedAdvancedTabId"
          :loading="selectedAdvancedTabId === deletingCategoryId" height="43" class="
            font-weight-bold
            text-capitalize
            py-3
            ml-3
          ">
          <v-icon size="16">mdi-delete</v-icon> Delete current sensor group
        </v-btn>
      </v-row>
      <v-card class="px-6 py-3">
        <h6 class="pt-12 text-muted text-center text-body-2" v-if="selectedAdvancedTabId === null">
          Let's create new sensor
        </h6>
        <h6 class="pt-12 text-muted text-center text-body-2" v-if="categoriesAdvanced.length === null">
          Let's create new device
        </h6>
        <v-tabs v-model="selectedAdvancedTabId" background-color="transparent" light
          next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" color="primary"
          show-arrows>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab v-for="item in categoriesAdvanced" :key="item._id" :href="'#' + item._id">
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedAdvancedTabId" class="px-4 pb-8 pt-2">
          <v-tab-item v-for="category in categoriesAdvanced" :key="category._id" :value="category._id">
            <v-row>
              <v-col md="4" cols="12" sm="6" lg="3" v-for="(item, i) in category.devices" :key="i">
                <v-card class="border-radius-xl card-shadow device-wrap device-advanced">
                  <div class="px-4 py-3 text-center">
                    <v-icon color="green darken-2" size="28">{{item.icon}}</v-icon>
                    <h1 class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    ">
                      <span>{{item.mqtt || " "}}</span>
                      <span class="text-lg">{{item.unit}}</span>
                    </h1>
                    <h6 class="mb-0 text-h6 font-weight-bolder text-typo"> {{item.name}}</h6>
                    <!-- <p class="opacity-8 mb-0 text-sm text-typo">{{item.name}}</p> -->
                  </div>
                  <v-icon class="icon" @click="deleteDevice(item._id)" size="24">mdi-delete-outline</v-icon>
                  <v-icon class="icon icon-edit" size="22" @click="editDevice(item)">mdi-pencil-outline</v-icon>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>

        <v-col lg="12" cols="12">
          <v-row class="px-0 pb-6 pt-3" v-if="categoriesAdvanced.length > 0">
            <v-btn elevation="0" :ripple="false" @click="toggleAddDevice(true)" height="43" class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-gradient-secondary
                  py-3
                  px-6
                  ms-3
                ">
              <v-icon size="16">mdi-plus-thick</v-icon> Add Device
            </v-btn>
          </v-row>
        </v-col>

      </v-card>

      <v-snackbar v-model="showSnackbar" :color="apiStatus" timeout="3000" fixed bottom outlined right>
        {{ message }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import mqtt from 'mqtt';
import { allIcons } from '../../util/icons';

export default {
  name: 'farm',
  components: {
  },

  data() {
    return {
      allIcons,
      mqttClient: {
        connected: false,
      },
      showAddDevice: false,
      isUnitForm: false,
      formData: {
        name: '',
        icon: '',
        topic: '',
        numbers: [],
        divisionCoefficient: '',
        unit: '',
        isSaveDB: false,
        isNotification: false,
        saveDBAt: '',
        minMQTTValue: '',
        maxMQTTValue: '',
      },
      error: [],
      isSaving: false,
      showSnackbar: false,
      message: '',
      apiStatus: '',
      selectedDeviceId: null,
      showAddPageOrSensor: false,
      isAddPage: false,
      categoriesNormal: [],
      categoriesAdvanced: [],
      selectedAdvancedTabId: null,
      selectedNormalTabId: null,
      deletingCategoryId: null,
      editingCategoryId: null,
    };
  },

  mounted: function () {
    this.fetchCategories();
  },

  methods: {
    async fetchCategories() {
      const { params: { id: factoryId } } = this.$route;
      const { data } = await this.$axios.get('api/category/' + factoryId);
      const normal = [];
      const advanced = [];

      data.forEach(item => {
        if (item.type === 'normal') {
          normal.push(item);
        } else {
          advanced.push(item);
        }
      });
      this.categoriesNormal = normal;
      this.categoriesAdvanced = advanced;
      this.getMqttConfig();
    },

    async getMqttConfig() {
      try {
        const { data } = await this.$axios.get('api/setting');
        this.createConnection(data);
      } catch (error) {
        console.log({ error });
      }
    },

    async submitDevice() {
      if (this.isSaving) {
        return;
      }

      this.error = [];
      this.showSnackbar = false;
      this.apiStatus = '';
      this.message = '';

      for (const item in this.formData) {
        if (this.formData[item] === '' || this.formData[item]?.length === 0) {
          this.error.push(item);
        }
      }

      // error of normal device
      if (this.error.length && !this.isUnitForm) {
        const fieldIgnored = ['unit', 'divisionCoefficient', 'minMQTTValue', 'maxMQTTValue', 'saveDBAt'];
        this.error = this.error.filter(item => !fieldIgnored.includes(item));
      }

      // error of advanced device
      if (this.isUnitForm && !this.formData.isSaveDB) {
        const fieldIgnored = ['minMQTTValue', 'maxMQTTValue', 'saveDBAt'];
        this.error = this.error.filter(item => !fieldIgnored.includes(item));
      }

      let apiError = null;

      // call api if error empty
      if (this.error.length === 0) {
        this.isSaving = true;
        let isSuccess = true;
        try {
          const payload = {
            name: this.formData.name,
            icon: this.formData.icon,
            mqttTopic: this.formData.topic,
            arrayNumber: [+this.formData.numbers],
            type: this.isUnitForm ? 'advanced' : 'normal',
            divisionCoeff: this.isUnitForm ? this.formData.divisionCoefficient : undefined,
            unit: this.isUnitForm ? this.formData.unit : undefined,
            categoryId: this.isUnitForm ? this.selectedAdvancedTabId : this.selectedNormalTabId,
            isSaveDB: this.formData.isSaveDB,
            isNotification: this.formData.isNotification,
            saveDBAt: this.formData.saveDBAt || '',
            minMQTTValue: this.formData.minMQTTValue || '',
            maxMQTTValue: this.formData.maxMQTTValue || '',
          };
          if (this.selectedDeviceId) {
            await this.$axios.put('/api/devices/' + this.selectedDeviceId, payload);
          } else {
            await this.$axios.post('/api/devices', payload);
          }
        } catch (error) {
          console.log(error);
          isSuccess = false;
          apiError = error.response?.data?.msg === 'Device is already exists'
            ? `Device is already exists with array number is [${this.formData.numbers}]`
            : error.response?.data?.msg;
        }
        this.isSaving = false;
        this.showSnackbar = true;
        this.apiStatus = isSuccess ? 'success' : 'error';
        this.message = apiError || (this.selectedDeviceId ? 'Edit' : 'Add') + (isSuccess ? ' device successfully' : ' device failed');
        if (isSuccess) {
          this.toggleAddDevice();
          this.fetchCategories();
        }
      }
    },

    async submitNewPageOrSensor() {
      if (this.isSaving) {
        return;
      }

      this.error = [];
      this.showSnackbar = false;
      this.apiStatus = '';
      this.message = '';

      if (this.formData.name === '' || this.formData.name.trim().length === 0) {
        this.error.push('name');
      }

      // call api if error empty
      if (this.error.length === 0) {
        this.isSaving = true;
        let isSuccess = true;
        try {
          if (this.editingCategoryId) {
            await this.$axios.put('/api/category/' + this.editingCategoryId, { name: this.formData.name });
            this.selectedAdvancedTabId = null;
            this.selectedNormalTabId = null;
            this.categoriesNormal = [];
            this.categoriesAdvanced = [];
          } else {
            const { params } = this.$route;
            const payload = {
              name: this.formData.name,
              type: this.isAddPage ? 'normal' : 'advanced',
              factoryId: params.id,
            };
            await this.$axios.post('/api/category', payload);
          }
        } catch (error) {
          isSuccess = false;
        }
        this.isSaving = false;
        this.showSnackbar = true;
        this.apiStatus = isSuccess ? 'success' : 'error';
        this.message = (isSuccess ? 'Save successfully' : 'Save failed');
        if (isSuccess) {
          this.fetchCategories();
          this.toggleAddPageOrSensor(false);
        }
      }
    },

    editDevice(item) {
      this.formData = {
        name: item.name,
        icon: item.icon,
        topic: item.mqttTopic,
        numbers: item.arrayNumber[0],
        divisionCoefficient: item.divisionCoeff || '',
        unit: item.unit || '',
        isSaveDB: item.isSaveDB || false,
        isNotification: item.isNotification || false,
        saveDBAt: item.saveDBAt || '',
        minMQTTValue: item.minMQTTValue || '',
        maxMQTTValue: item.maxMQTTValue || '',
      };
      this.isUnitForm = item.type === 'advanced';
      this.selectedDeviceId = item._id;
      this.showAddDevice = true;
    },

    async deleteDevice(deviceId) {
      try {
        await this.$axios.delete('api/devices/' + deviceId);
        this.fetchCategories();
      } catch (error) {
        console.log({ error });
      }
    },

    toggleAddPageOrSensor(isPage = false) {
      this.showAddPageOrSensor = !this.showAddPageOrSensor;
      this.isAddPage = isPage;
      this.formData.name = '';
      this.error = [];
      this.editingCategoryId = null;
    },

    toggleAddDevice(isUnit = false) {
      this.isUnitForm = isUnit;
      this.showAddDevice = !this.showAddDevice;
      this.selectedDeviceId = null;
      this.error = [];
      this.formData = {
        name: '',
        icon: '',
        topic: '',
        numbers: [],
        divisionCoefficient: '',
        unit: '',
        isSaveDB: false,
        isNotification: false,
        saveDBAt: '',
        minMQTTValue: '',
        maxMQTTValue: '',
      };
    },

    createConnection(config) {
      try {
        this.mqttClient = mqtt.connect(config.url, {
          username: config.username,
          password: config.password,
        });
      } catch (error) {
        console.log('mqtt.connect error', error);
      }
      this.mqttClient.on('connect', () => {
        console.log('Connection succeeded!');
        this.mqttSubscribeTopics();
      });
      this.mqttClient.on('error', (error) => {
        console.log('Connection failed', error);
      });
    },

    async deletePageOrSensor(id) {
      if (!this.deletingCategoryId) {
        this.deletingCategoryId = id;
        try {
          await this.$axios.delete('/api/category/' + id);
          this.selectedAdvancedTabId = null;
          this.selectedNormalTabId = null;
          this.fetchCategories();
        } catch (error) {
          console.log(error);
        }
        this.deletingCategoryId = null;
      }
    },

    editPageOrSensor(isPage = false) {
      this.isAddPage = isPage;
      const category = isPage
        ? this.categoriesNormal.find(item => item._id === this.selectedNormalTabId)
        : this.categoriesAdvanced.find(item => item._id === this.selectedAdvancedTabId);
      this.showAddPageOrSensor = true;
      this.formData.name = category.name;
      this.editingCategoryId = category._id;
    },

    receiveNewMessage(topic, message) {
      const Temp = JSON.parse(message);

      this.categoriesNormal = this.categoriesNormal.map(item => {
        item.devices = item.devices.map(d => {
          if (d.mqttTopic === topic) {
            const index = d.arrayNumber[0];
            d.mqtt = Temp[index] || 0;
          }
          return d;
        });
        return item;
      });

      this.categoriesAdvanced = this.categoriesAdvanced.map(item => {
        item.devices = item.devices.map(d => {
          if (d.mqttTopic === topic) {
            const index = d.arrayNumber[0];
            d.mqtt = Number((Temp[index] || 0) * Number(d.divisionCoeff)).toFixed(1);
          }
          return d;
        });
        return item;
      });
    },

    mqttSubscribeTopics() {
      if (this.mqttClient.connected) {
        const allDevices = [];
        [...this.categoriesNormal, ...this.categoriesAdvanced].forEach(item => {
          allDevices.push(...item.devices);
        });

        allDevices.forEach(({ mqttTopic }) => this.mqttClient.subscribe(mqttTopic));
        this.mqttClient.on('message', this.receiveNewMessage);
      }
    }
  },
};
</script>

<style lang="scss">
.prepend-inner {
  height: 43px;

  .v-input__prepend-inner {
    margin-top: 0 !important;
  }

  .v-select__slot input {
    padding: 2px 0 !important;
  }
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.device-wrap {
  position: relative;

  &.device-advanced .icon {
    right: 8px;
    top: 8px;

    &.icon-edit {
      right: 32px;
      top: 9px;
    }
  }

  .icon {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
    display: none;

    &.icon-edit {
      right: 24px
    }
  }

  &:hover {
    .icon {
      display: block;
    }
  }
}
</style>
