<template>
  <v-container fluid class="">
    <v-row>
      <v-col lg="12" cols="10" class="mx-auto">
        <v-card style="padding: 25px">
          <div>
            <h5 class="font-weight-bolder text-h5 text-typo mb-0">
              Chat with AI
            </h5>
            <v-row class="mt-2">
              <v-col sm="12" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Any Question?</label
                >

                <v-text-field
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  class="
                    font-size-input
                    placeholder-lighter
                    text-light-input
                    border border-radius-md
                    mt-2
                  "
                  v-model="newMessage"
                >
                </v-text-field>
                <br />
                <div v-if="question">
                  <strong>Question:</strong> {{ question }}
                </div>
                <br />
                <div v-if="responseMessage" style="width: 100%">
                  <strong>GreenOS GPT:</strong>
                  <div v-html="responseMessageHTML"></div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="text-end">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-default
                py-5
                px-6
                mt-6
                mb-2
                me-2
              "
              color="primary"
              :loading="isLoading"
              @click="sendMessage"
            >
              Ask
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { marked } from "marked";

export default {
  name: "Profile-Message",
  data: function () {
    return {
      newMessage: "",
      question: "",
      responseMessage: "",
      isLoading: false,
    };
  },
  computed: {
    responseMessageHTML() {
      return marked(this.responseMessage, { sanitize: true });
    },
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.question = this.newMessage;
        this.isLoading = true;
        this.responseMessage = "";
        this.$axios
          .post(`/api/chat`, {
            text: this.newMessage,
          })
          .then((response) => {
            this.responseMessage = response.data.content;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  border-radius: 50%;
  width: 10em;
  height: 10em;
  border: 1.1em solid rgba(127, 127, 127, 0.2);
  border-left-color: #0f3e75;

  animation: spinner 1.1s infinite linear;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
