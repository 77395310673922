<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <div class="v-navigation-drawer-brand pa-3 d-flex align-center">
        <v-img
          src="@/assets/img/logo-ct.png"
          class="navbar-brand-img ms-3"
          width="175"
        >
        </v-img>
      </div>

      <p class="mb-0 text-body">Enter your email and password to login.</p>
    </div>

    <div class="card-padding pb-4">
      <form v-on:keyup.enter="login">
        <label class="text-xs text-typo font-weight-bolder ms-1"
          >Email Adress</label
        >
        <v-text-field
          v-model="username"
          hide-details
          outlined
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          height="40"
          placeholder="Username"
          class="
            input-style
            font-size-input
            border border-radius-md
            placeholder-lighter
            text-color-light
            mb-4
            mt-2
          "
        >
        </v-text-field>

        <label class="text-xs text-typo font-weight-bolder ms-1"
          >Password</label
        >
        <v-text-field
          v-model="password"
          hide-details
          outlined
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          height="40"
          type="password"
          placeholder="Password"
          class="
            input-style
            font-size-input
            border border-radius-md
            placeholder-lighter
            text-color-light
            mb-4
            mt-2
          "
        >
        </v-text-field>

        <v-btn
          @click="login"
          @keyup.enter="login"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-primary
            bg-gradient-primary
            py-2
            px-6
            me-2
            mt-7
            mb-2
            w-100
          "
          type="submit"
          color="#5e72e4"
          small
          >Sign In</v-btn
        >
        <div class="text-center">{{ error }}</div>
      </form>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Signin",
  data: () => {
    return {
      username: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login() {
      let User = {
        username: this.username,
        password: this.password,
      };

      this.$axios
        .post(`/api/user/signin`, User)
        .then(
          (res) => {
            if (res.status == 200) {
              localStorage.setItem("token", res.data.token);
              this.$router.push("/");
            }
          },
          (err) => {
            this.error = err.response.data.error;
          }
        );
    },
  },
};
</script>
